<template>
  <b-card-actions action-collapse title="Daftar Gudang">
    <!-- ref="refreshCard" -->
    <!-- action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-tambah variant="primary"
          v-if="allowCreate()">
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal v-if="allowCreate()" v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary"
          ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
          <b-form>
            <b-form-group>
              <label for="pilih">Pilih gudang untuk?</label>
              <!-- <div> -->
              <!-- <b-form-radio-group
      v-model="selected"
      :options="options"
      class="demo-inline-spacing mb-1"
      value-field="value"
      text-field="text"
      disabled-field="disabled"
      @change="getDataMember()"
    /> -->
              <b-form-radio v-model="selected" name="some-radios" value="Pusat">Pusat</b-form-radio>
              <b-form-radio v-model="selected" name="some-radios" value="Cabang">Cabang</b-form-radio>
              <b-form-radio v-model="selected" name="some-radios" value="Marketing">Marketing</b-form-radio>

              <b-card-text class="mt-1 mb-0">
                Pilihan: <strong>{{ selected }}</strong>
              </b-card-text>
              <!-- </div> -->
            </b-form-group>
            <b-form-group>
              <label for="nama_gudang">Nama Gudang</label>
              <b-form-input v-model="form.nama_gudang" id="gudang"  placeholder="Nama gudang" />
            </b-form-group>
            <b-form-group>
              <label for="alamat">Alamat</label>
              <b-form-textarea v-model="form.alamat" id="alamat" placeholder="Alamat" />
            </b-form-group>
            <b-form-group>
              <label for="keterangan">Keterangan</label>
              <b-form-textarea v-model="form.keterangan" id="keterangan"  placeholder="Keterangan" />
            </b-form-group>
            <b-form-group >
              <label for="admin">Admin Gudang</label>
              <b-form-select v-model="form.karyawan_id" :options="karyawan_id" label="admin" />
            </b-form-group>
            <!-- <b-form-group v-if="selected == 'Cabang'">
              <label for="cbg">Admin Cabang</label>
              <b-form-select v-model="form.member_id" :options="member_id" label="cbg" />
            </b-form-group>
            <b-form-group v-if="selected == 'Marketing'">
              <label for="cbg">Admin Marketing</label>
              <b-form-select v-model="form.member_id" :options="member_id" label="cbg" />
            </b-form-group> -->
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="success" @click="submit">
                Tambah
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalTambah = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-if="allowUpdate()" v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah"
          cancel-title="Batal" centered title="Form Ubah">
          <b-form>
            <b-form-group>
              <label for="pilih">Pilih gudang untuk?</label>
              <!-- <div> -->
              <!-- <b-form-radio-group
      v-model="selected"
      :options="options"
      class="demo-inline-spacing mb-1"
      value-field="value"
      text-field="text"
      disabled-field="disabled"
      @change="getDataMember()"
    /> -->
              <b-form-radio v-model="selected" name="some-radios"
                value="Pusat">Pusat</b-form-radio>
              <b-form-radio v-model="selected" name="some-radios"
                value="Cabang">Cabang</b-form-radio>
              <b-form-radio v-model="selected" name="some-radios"
                value="Marketing">Marketing</b-form-radio>

              <b-card-text class="mt-1 mb-0">
                Pilihan: <strong>{{ selected }}</strong>
              </b-card-text>
              <!-- </div> -->
            </b-form-group>
            <b-form-group>
              <label for="nama_gudang">Nama Gudang</label>
              <b-form-input v-model="form.nama_gudang" id="gudang"  placeholder="Nama gudang" />
            </b-form-group>
            <b-form-group>
              <label for="alamat">Alamat</label>
              <b-form-textarea v-model="form.alamat" id="alamat" placeholder="Alamat" />
            </b-form-group>
            <b-form-group>
              <label for="keterangan">Keterangan</label>
              <b-form-textarea v-model="form.keterangan" id="keterangan"  placeholder="Keterangan" />
            </b-form-group>
            <b-form-group>
              <label for="admin">Admin Gudang Pusat</label>
              <b-form-select v-model="form.karyawan_id" :options="karyawan_id" label="admin" />
            </b-form-group>
            <!-- <b-form-group v-if="selected == 'Cabang'">
              <label for="cbg">Admin Cabang</label>
              <b-form-select v-model="form.member_id" :options="member_id" label="cbg" />
            </b-form-group>
            <b-form-group v-if="selected == 'Marketing'">
              <label for="cbg">Admin Marketing</label>
              <b-form-select v-model="form.member_id" :options="member_id" label="cbg" />
            </b-form-group> -->
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalEdit = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search"  />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ (row.index + 1) }}
            </strong>
          </template>

          <template #cell(teritory_id)="data">
            <b-badge variant="primary">
              {{ getKaryawanName(data.item) }}
            </b-badge>
          </template>
          <template #cell(admin)="data">
            <!-- <b-badge variant="primary"> -->
            {{ data.item.karyawan ? data.item.karyawan.nama_lengkap : data.item.member ? data.item.member.nama_lengkap :
              '-' }}
            <!-- </b-badge> -->
          </template>
          <template #cell(sebagai)="data">
            <!-- <b-badge variant="primary"> -->
            {{ data.item.karyawan ? 'Pusat' : data.item.member ? data.item.member.sebagai.toUpperCase() :
              '-' }}
            <!-- </b-badge> -->
          </template>

          <template #cell(actions)="row">
            <!-- <b-dropdown
              id="dropdown-3"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <span class="mr-1"></span>
                <feather-icon icon="ListIcon" size="16" class="align-middle" />
              </template> -->
            <!-- <b-dropdown-item> -->
            <!-- <feather-icon icon="ListIcon" /> -->
            <!-- <b-dropdown-item
                ><b-button
                  v-b-tooltip.hover.right="'Detail'"
                  size="sm"
                  @click="info(row.item, row.index, $event.target)"
                  class="mr-1"
                  variant="outline-secondary"
                >
                  <feather-icon icon="ListIcon" />Detail
                </b-button></b-dropdown-item
              > -->
            <!-- <b-dropdown-item >-->
            <b-button v-if="allowUpdate()" v-b-tooltip.hover.right="'Tambah Staff Gudang'" size="sm" @click="addkasir(row.item)"
              v-b-modal.modal-kasir class="mr-1" variant="outline-primary ">
              <feather-icon icon="UserPlusIcon" />
              Tambah Staff Gudang
            </b-button>
            <b-button v-if="allowUpdate()" v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- </b-dropdown-item> -->
            <!-- <b-dropdown-item> -->
            <b-button v-if="allowDelete()" v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)"
              class="mr-1" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
            <!-- </b-dropdown-item> -->
            <!-- </b-dropdown> -->

            <!-- <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button> -->
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
    <b-modal v-if="allowCreate()" v-model="modalkasir" id="modal-kasir" cancel-variant="secondary" ok-title="Tambah"
      cancel-title="Batal" centered title="Tentukan Staff Gudang" size="xl">
      <b-row>
          <!-- List Kasir di penerimaan -->
          <b-col sm="12" md="8" lg="8" xl="8">
            <b-card title="Staff yang sudah terdaftar di Gudang">
              <b-row>
                <b-col sm="6" md="6" lg="6">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select id="perPageSelect" v-model="perPageKasirs" size="sm" :options="pageOptionsKasirs" class="w-50" />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6" lg="6" >
                  <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm"
                    label-for="sortBySelect" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-select id="sortBySelect" v-model="sortByKasirs" :options="sortOptionsKasirs" class="w-75">
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select v-model="sortDescKasirs" size="sm" :disabled="!sortByKasirs" class="w-25">
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="12">
                  <b-form-group>
                    <label for="Cari Kasir">Cari Staff Gudang</label>
                    <b-input-group>
                      <b-form-input v-model="cariKasir" placeholder="Tekan enter untuk cari"
                        @keydown.enter.prevent="getDataKasirs()"></b-form-input>
                      <!-- <b-form-input placeholder="Button on right" /> -->
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="getDataKasirs()">
                          Cari
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="12">
                  <b-table v-if="loaded" striped small hover responsive :per-page="perPageKasirs" :items="dataKasirs"
                    :fields="fieldsKasirs" :sort-by.sync="sortByKasirs" :sort-desc.sync="sortDescKasirs"
                    :sort-direction="sortDirectionKasirs" :filter-included-fields="filterOnKasirs"
                    @filtered="onFilteredKasirs">
                    <template #cell(no)="row">
                      <strong class="text-center">
                        {{ row.index + 1 }}
                      </strong>
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item ? item.kode : '-' }}
                    </template>
                    <template #cell(nama)="{ item }">
                      {{ item.kasir ? item.kasir.nama_lengkap : '-' }}
                    </template>
                    <template #cell(email)="{ item }">
                      {{ item.kasir ? item.kasir.email : '-' }}
                    </template>
          <template #cell(actions)="row">
            <b-button v-if="allowDelete()" v-b-tooltip.hover.right="'Hapus'" size="sm" @click="removekasir(row.item)"
              class="mr-1" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination v-model="currentPageKasirs" :total-rows="totalRowsKasirs" :per-page="perPageKasirs"
                    align="center" size="sm" class="my-0" />
                </b-col>
              </b-row>
      </b-card>
      </b-col>
      <b-col sm="12" md="4" lg="4" xl="4">
            <b-card title="Tentukan Staff Gudang Baru">
              <b-form>
        <b-form-group>
          <label for="nama_gudang">Nama Gudang</label>
          <b-form-input disabled v-model="form.nama_gudang" id="gudang"  placeholder="Nama gudang" />
        </b-form-group>
        <b-form-group>
          <label for="admin">Pilih Staff</label>
          <b-form-select v-model="form.karyawan_id" :options="karyawankasir_id" label="admin" />
        </b-form-group>
      </b-form>
              </b-card>
              </b-col>
              </b-row>

      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" class="mr-1" variant="success" @click="kasiradd()">
            Tambah
          </b-button>
          <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
            Hapus isian
          </b-button>
          <b-button size="sm" variant="danger" @click="modalkasir = false">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox, BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions, ToastificationContent,
    VBTooltip,
    BModal, BFormTextarea,
    BDropdown,
    BDropdownItem,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
    dataKasirs: null,
    cariKasir: null,
    pageOptionsKasirs: [10, 20, 50],
    sortByKasirs: null,
    sortDescKasirs: false,
    sortDirectionKasirs: "asc",
    filterKasirs: null,
    filterOnKasirs: [],
    perPageKasirs: 10,
    totalRowsKasirs: 1,
    currentPageKasirs: 1,
    loaded: true,
    fieldsKasirs: [
      // { key: "penyimpanan", label: "Pilih" },
      { key: "no", label: "No", sortable: true },
      { key: "nama", label: "Nama Kasir", sortable: true },
      { key: "email", label: "Email" },
      // { key: "varian", label: "Varian" },
      // { key: "satuan", label: "Satuan" },
      { key: "actions", label: "#" },
    ],
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      form: {
        nama_gudang: null,
        jenis: 1,
        member_id: null,
        alamat: "-",
        karyawan_id: null,
        keterangan: "",
      },
      teritory_id: [],
      sales: [],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        { key: "nama_gudang", label: "Nama Gudang", sortable: true },
        // { key: "teritory_id", label: "Teritori", sortable: true },
        { key: "admin", label: "Admin" },
        { key: "sebagai", label: "Sebagai" },
        { key: "alamat", label: "Alamat" },
        { key: "keterangan", label: "Keterangan" },
        // { key: "delete", label: "Hapus"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      jabatan_ids: [],
      karyawan_id: [],
      member_id: [],
      selected: 'Pusat',
      options: [
        { text: 'Pusat', value: 'Pusat', disabled: false },
        { text: 'Cabang', value: 'Cabang', disabled: false },
        { text: 'Marketing', value: 'Marketing', disabled: false },
      ],
      modalkasir: false, jabatankasir_ids: [],
      karyawankasir_id: [],
    };
  },
  watch: {
    "selected"(val) {
      if (val == 'Cabang') {
        this.getDataMember()
      } else if (val == 'Marketing') {
        this.getDataMember()
      } else {
        this.getDataKaryawan()
      }
    },
    perPageKasirs(value) {
      if (value) {
        this.getDataKasirs();
      }
    },
    // filterKasirs(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getDataKasirs();
    //   }
    // },
    currentPageKasirs(val) {
      if (val) {
        this.getDataKasirs();
        // this.items
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptionsKasirs() {
      // Create an options list from our fields
      return this.fieldsKasirs
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // Set the initial number of items
    await this.getData()
    await this.getDataJabatan()
    this.getDataKaryawan()
    this.getDataMember()
    // this.getDatasubmenu()

  },
  methods: {
    onFilteredKasirs(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsKasirs = filteredItems.length;
      this.currentPageKasirs = 1;
    },
    add() {
      this.id = null
      this.activeAction = 'tambah'
      this.showModalTambah = true
      this.showModalEdit = false
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data gudang ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('gudang/save', [item])
            .then(() => {
              this.getData()
              this.pesanBerhasilHapus()
            })
            .catch(e => {
              this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    removekasir(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Kasir ini akan dihapus dari gudang ini?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('kasir/save', [item])
            .then(() => {
              this.getDataKasirs()
              this.pesanBerhasilHapus()
            })
            .catch(e => {
              this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
      this.form = {
        teritori: 0,
        nama_gudang: 0,
        sub_menu: 0,
        alamat: "",
        create: null,
        update: null,
        delete: null,
      }
    },
    submit() {
      if (this.form.nama_gudang == null || this.form.nama_gudang == '') {
        this.pesanGagalSimpan()
        return false
      }
      if (this.id) {
        this.form.id = this.id
      }
      const payload = {
        member_id: this.form.member_id,
        nama_gudang: this.form.nama_gudang,
        alamat: this.form.alamat,
        keterangan: this.form.keterangan,
        karyawan_id: this.form.karyawan_id,
        jenis: 1,
      }
      this.$store.dispatch('gudang/save', [payload])
        .then(() => {
          if (this.activeAction == 'tambah') {
            this.id = null
          }
          this.getData()
          this.pesanBerhasilSimpan()
          this.resetForm()
          this.showModalTambah = false
          this.showModalEdit = false
        })
        .catch(e => console.error(e))
      // this.pesanGagalSimpan()
    },
    edit(item) {
      this.id = item.id
      this.form = item
      this.showModalEdit = true
    },
    addkasir(item) {
      this.getDataJabatanKasir()
      // this.getDataKaryawanKasir()
      this.id = item.id
      this.form = item
      this.modalkasir = true
      this.getDataKasirs()
    },
    kasiradd() {
      if (this.form.karyawan_id == null || this.form.karyawan_id == '') {
        this.pesanGagalSimpan()
        return false
      }
      // if (this.id) {
      //   this.form.id = this.id
      // }
      const payload = {
        kasir_id: this.form.karyawan_id,
        toko_id: this.form.id,
      }
      this.$store.dispatch('kasir/save', [payload])
        .then(() => {
          if (this.activeAction == 'tambah') {
            this.id = null
          }
          this.getData()
          this.pesanBerhasilSimpan()
          this.resetForm()
          this.showModalTambah = false
          this.showModalEdit = false
          this.modalkasir = false
        })
        .catch(e => console.error(e))
      // this.pesanGagalSimpan()
    },
    pesanBerhasilSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menyimpan data',
          variant: 'success',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanGagalSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menyimpan data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanBerhasilHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menghapus data',
          variant: 'success',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanGagalHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menghapus data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    async getDataJabatan() {
      const jabatans = await this.$store.dispatch('jabatan/getData')
      const requiredKeys = ['admin_gudang', 'admin_asset', '']

      jabatans.map(jb => {
        if (
          jb.nama_jabatan.match(/gudang/gi) ||
          jb.nama_jabatan.match(/aset/gi) ||
          jb.nama_jabatan.match(/asset/gi)
        ) {
          this.jabatan_ids.push(jb.id)
        }
      })
    },
    async getDataJabatanKasir() {
      const jabatans = await this.$store.dispatch('jabatan/getData')
      const requiredKeys = ['Staff Gudang', 'staff gudang', '']

      jabatans.map(jb => {
        if (
          jb.nama_jabatan.match(/Staff Gudang/gi) ||
          jb.nama_jabatan.match(/staff gudang/gi) ||
          jb.nama_jabatan.match(/staff/gi) ||
          jb.nama_jabatan.match(/Staff WH/gi) ||
          jb.nama_jabatan.match(/Staff Warehouse/gi)
        ) {
          this.jabatankasir_ids.push(jb.id)
        }
      })
      this.getDataKaryawanKasir()
      // this.jabatankasir_ids = jabatans
    },
    async getDataKaryawanKasir() {
      const params = {}
      // if (this.jabatankasir_ids.length > 0) {
        params.jabatan_id = this.jabatankasir_ids.join(',')
      // }
      this.$store.dispatch('karyawan/getData', params)
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas))
          ter.map(item => {
            item.value = item.id
            item.text = item.nama_lengkap
          })
          this.karyawankasir_id = ter
        })
    },
    getData() {
      this.$store.dispatch('gudang/getData', { jenis: 1 })
        .then(() => {
          this.items = this.$store.state.gudang.datas
          this.totalRows = this.items.length;
        })
        .catch(e => console.error(e))
    },
    getDataMember() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        sebagai: this.selected == 'Cabang' ? 'cabang' : 'marketing'
      }
      this.$store.dispatch("member/getData", payload).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        ter.map(item => {
          item.value = item.id
          item.text = item.nama_lengkap
        })
        this.member_id = ter
      });
    },
    getDataKaryawan() {
      const params = {}
      if (this.jabatan_ids.length > 0) {
        params.jabatan_id = this.jabatan_ids.join(',')
      }
      this.$store.dispatch('karyawan/getData', params)
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas))
          ter.map(item => {
            item.value = item.id
            item.text = item.nama_lengkap + ' - ' + item.email
          })
          this.karyawan_id = ter
        })
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      // const payload = {
      // search: this.filter != null ? this.filter : null,
      // order: "desc",
      // start: currentPage,
      // length: this.perPage,
      // filter_by: "nama_lengkap",
      // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
      // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
      // sebagai: 'cabang',
      // user_id: this.user.id,
      // };
      // this.$store.dispatch("member/getData", payload).then(() => {
      //   let ter = JSON.parse( JSON.stringify(this.$store.state.member.datas));
      //   ter.map(item => {
      //     item.value = item.id
      //     item.text = item.nama_lengkap
      //   })
      //   this.karyawan_id = ter
      // });
    },
    getKaryawanName(data) {
      let find = this.karyawan_id.find(item => item.karyawan && item.id == data.karyawan.id)
      return find ? find.nama_lengkap : '-'
    },

    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDataKasirs() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPageKsr = parseInt(this.perPageKasirs);
      const currentPageKsr = (parseInt(this.currentPageKasirs) - 1) * perPageKsr;
      const payload = {
        search: this.cariKasir != null ? this.cariKasir : null,
        order: "desc",
        start: currentPageKsr,
        length: this.perPageKasirs,
        gudang_id: this.id,
        jenis : 2
        // filter_by: "nama_lengkap",
        // kode: this.cariKasir ? this.cariKasir : "",
      };
      this.$store
        .dispatch("kasir/getData", payload)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kasir.datas));
          let items_total = this.$store.state.kasir.totals;
          this.dataKasirs = items;
          const KasirsId = response.map(kasir => kasir.id)
          this.totalRowsKasirs = items_total;
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
  },
};
</script>
